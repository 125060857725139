import axios from 'axios'
import Cookies from 'js-cookie'

export function reques(config) {
    // 1.创建axios实例对象
    const instance = axios.create({   
        baseURL: '//www.zjfda-tc.org.cn/educationPortalServer',
        // baseURL:'https://medicine.yfhpgm.cn/educationPortalServer',
        timeout: 5000
    })

    function Get(name) {
        return Cookies.get(`admin-${name}`);
    };

    // 2.axios（网络）请求拦截器
    // 拦截的作用
    // 2.1拦截一些config里没有用的信息
    // 2.2每次发送网络请求等待时，有需求需要在界面中显示等待图标
    // 2.3某些网络请求需要携带一些特殊的信息如(token)
    instance.interceptors.request.use(
        config => {
            // 在请求发送之前做一些处理
            // const token = util.cookies.get('token');
            // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
            let token = Get('token');

            config.headers['adminToken'] = token;
            return config;
        },
        error => {
            // 发送失败
            console.log(error);
            Promise.reject(error);
        }
    );

    //响应拦截器
    instance.interceptors.response.use(
        response => {
            // dataAxios 是 axios 返回数据中的 data
            const dataAxios = response;
            // 这个状态码是和后端约定的
            const { code } = dataAxios.data;
            // 根据 code 进行判断
            if (code === undefined) {
                // 如果没有 code 代表这不是项目后端开发的接口
                return dataAxios;
            } else {
                // 有 code 代表这是一个后端接口 可以进行进一步的判断
                switch (code) {
                    case 200:
                        // [ 示例 ] code === 0 代表没有错误
                        return dataAxios;
                    case 301:
                        // [ 示例 ] code === 0 代表没有错误
                        return dataAxios;
                    case 500:
                        // [ 示例 ] 其它和后台约定的 code
                        // Toast(dataAxios.data.msg)
                        return dataAxios;
                        // errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
                        break;
                    case 900:
                        // [ 示例 ] 其它和后台约定的 code
                        Toast(dataAxios.data.msg)
                        return dataAxios;
                        // errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
                        break;
                    default:
                        // 不是正确的 code
                        // Toast(dataAxios.data.msg)
                        // errorCreate(`${dataAxios.msg}: ${response.config.url}`);
                        break;
                }
            }
        },
        error => {
            if (error && error.response) {
                switch (error.response.status) {
                    case 400:
                        error.message = '请求错误';
                        break;
                    case 401:
                        error.message = '未授权，请登录';
                        break;
                    case 403:
                        error.message = '拒绝访问';
                        break;
                    case 404:
                        error.message = `请求地址出错: ${error.response.config.url}`;
                        break;
                    case 408:
                        error.message = '请求超时';
                        break;
                    case 500:
                        error.message = '服务器内部错误';
                        break;
                    case 501:
                        error.message = '服务未实现';
                        break;
                    case 502:
                        error.message = '网关错误';
                        break;
                    case 503:
                        error.message = '服务不可用';
                        break;
                    case 504:
                        error.message = '网关超时';
                        break;
                    case 505:
                        error.message = 'HTTP版本不受支持';
                        break;
                    default:
                        break;
                }
            }
            return Promise.reject(error);
        }
    );

    // 3.发送真正的网络请求
    return instance(config);

}