<template>

    <div class="headerbox">
        <div class="onebox">
            <img src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1628580244246.png" style="height:80px" alt="">
            <div class="logozi">
                <div class="logozi-one">
                    浙江省药品信息宣传和发展服务中心
                </div>
                <div class="logozi-two">
                    (浙江省药品监督管理局行政受理中心)
                </div>
            </div>
              <!-- <Input  v-model="message" search enter-button placeholder="全站搜索" /> -->
            <Input class="sousuo" v-model="message" placeholder="全站搜索"  /><Button class="btt" type="primary" @click.native="search()"  icon="ios-search">搜索</Button>
              <a class="jixu" href="http://mpsc.zjfda-tc.org.cn"  target="_blank">继续教育学习入口</a>
        </div>
        <div class="twobox">
            <div class="tabs">
                <router-link class="shou" to="/index">首页</router-link>
                         <Dropdown >
                             <router-link to="/xinx" class="xinxx">信息公开</router-link>
                        <Dropdown-menu slot="list" class="xiala">
                            <div class="cont">
                                <Dropdown-item @click.native="Introduction()">机构简介</Dropdown-item>
                                <Dropdown-item @click.native="setup()">机构设置</Dropdown-item>
                                <Dropdown-item @click.native="contact()">联系我们</Dropdown-item>
                                <Dropdown-item @click.native="announcement()">公告通知</Dropdown-item>
                            </div>
                         </Dropdown-menu>
                        </Dropdown>  
                        <Dropdown>
                         <router-link  class="news" to="/news">新闻中心</router-link>
                        <Dropdown-menu slot="list" class="xialac">
                            <div class="contt">
                                <Dropdown-item @click.native="center()" class="jj">工作动态</Dropdown-item>
                                <Dropdown-item class="jj"  @click.native="industry()" >业内新闻</Dropdown-item>
                            </div>
                         </Dropdown-menu>
                       </Dropdown>  
            
                <router-link  class="policy" to="/policy">政策法规</router-link>
            </div>
        </div>
    </div>
 
</template>
<script>
import {searchMessage} from "../woke/xinx"
export default {
  data() {
    return {
       num:0,
       message: "",
       currentPage:1,//初始页
       pageSize: 15,//每页显示条目数
    }
    
    
  },
  created(){
   
  },
  methods:{
      search(){
          if(this.message==""){
              this.$Message.warning('请先输入内容');
          }
          else{
               this.$router.push({
                  path: "/qsearch",
                  query: { message: this.message, currentPage:this.currentPage, pageSize: this.pageSize}
              })
             this.$bus.$emit('emitSearch',{ message: this.message, currentPage:this.currentPage, pageSize: this.pageSize})
             this.message=""
        
          }
          
      },
      //机构简介
      Introduction(){
          this.$router.push('/Introduction')
      },
      //机构设置
      setup(){
          this.$router.push("/setup")
      },
      //联系我们
      contact(){
          this.$router.push("/contact")
      },
      //公告通知
      announcement(){
          this.$router.push("/announcement")
      },
      //中心新闻
      center(){
          this.$router.push("/news")
      },
      //业内新闻
      industry(){
          this.$router.push("/industry")
      }
  },
}
</script>
<style lang="less" scoped>

.headerbox{
    width: 1903px;
    height: 120px;
    background: #FFFFFF;
    margin-bottom: 30px;
}
.onebox{
    width: 1200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    margin-left: 360px;
    margin-top: 27px;
    margin-bottom: 27px;
   
}
.logo{
   height: 80px;
}
.logozi{
    width: 800px;
   margin-left: 15px;
}
.logozi-one{
    color: #1A1A1A;
    font-size:26px;
    font-weight: bold;
    font-family: MicrosoftYaHei;
}
.logozi-two{
    color: #1A1A1A;
    font-size:26px;
    font-weight: bold;
    font-family: MicrosoftYaHei;
   
}
.sousuo{
   width: 359px;
   height: 50px;
   background: #F3F3F3;
   margin-top: 15px;
//    margin-left: 60px;
}
.jixu{
    width: 228px;
    height: 50px;
    FILTER: progid:DXImageTransform.Microsoft.Gradient(gradientType=0,startColorStr=#FFC87E,endColorStr=#F4A16F); /*IE 6 7 8*/ 

    background: -ms-linear-gradient(left, #FFC87E,  #F4A16F);        /* IE 10 */

    background:-moz-linear-gradient(left,#FFC87E,#F4A16F);/*火狐*/ 

    background:-webkit-gradient(left, 0% 0%, 0% 100%,from(#FFC87E), to(#F4A16F));/*谷歌*/ 

    background: -webkit-gradient(left, 0% 0%, 0% 100%, from(#FFC87E), to(#F4A16F));      /* Safari 4-5, Chrome 1-9*/

    background: -webkit-linear-gradient(left, #FFC87E, #F4A16F);   /*Safari5.1 Chrome 10+*/

    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
}
.twobox{
    margin-top: 60px;
    width: 1903px;
    height: 80px;
    background: #005CB0;
}
.tabs{
    width: 1000px;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #B7DDFFFF;
    line-height: 80px;
    margin-left: 400px;
}
.shou{
    width: 300px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
 
}
.shou:hover{
    color:#FFFFFFFF;
}
/deep/.ivu-select-dropdown{
    margin-top: -1px;
}
.gongkai{
    width: 300px;
    background:#013D74FF;
}
.xialac{
   width: 100px;
     height: 40px;
}
.cont{
    width: 100px;
     height: 130px;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 24px;
    // margin-top: -10px;
}
.contt{
     width: 100px;
     height: 120px;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 24px;
}
.jj{
    height: 28px;
}
.xialac{
    height: 60px;
}
.ivu-dropdown-item{
     /* color: #FFFFFFFF; */
}
.xinxx{
      width: 300px;
      text-align: center;
     cursor: pointer;
     font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #9BCFFF;
}
.xinxx:hover{
    color:#FFFFFFFF;
}
.news{
    width: 300px;
    text-align: center;
     cursor: pointer;
     font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #9BCFFF;
}
.news:hover{
    color:#FFFFFFFF ;
}
.new{
    width: 300px;
}
.policy{
    width: 300px;
    text-align: center;
     cursor: pointer;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #9BCFFF;
}
.policy:hover{
    color:#FFFFFFFF ;
}
/deep/.ivu-input {
    height: 50px;
}
.btt{
    background: #005CB0;
    margin-top: 15px;
    height: 50px;
    font-size: 16px;
    // margin-left: -20px;
}
/deep/.ivu-dropdown-rel{
     width: 300px;
      text-align: center;
     cursor: pointer;
   font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
}
.router-link-exact-active{
   
  color: #FFFFFFFF;
}

</style>