<template>
  <div id="app">
    <Header></Header>
   
        <router-view />
  
    <Footer></Footer>
  </div>
</template>

<style>
@import url("./assets/css/common.css");
body{
	margin: 0;
	padding: 0;
}
</style>
<script>
import Header from "./components/heder";
import Footer from "./components/footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>
