import Vue from 'vue'
import VueRouter from 'vue-router'



// 重写路由push方法,阻止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

// 重写路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

//首页
const Index = () =>
    import ('@/views/index/index')
    // 信息公开
const Introduction = () =>
    import ('@/views/main/xinx/Introduction')
    // 机构简介
const Xinx = () =>
    import ('@/views/main/xinx/xinx')
    // 机构设置
const Setup = () =>
    import ('@/views/main/xinx/setup')
    // 联系我们
const Contact = () =>
    import ('@/views/main/xinx/contact')
    // 公告通知
const Announcement = () =>
    import ('@/views/main/xinx/announcement')
    // 咨询
const Zixun = () =>
    import ('@/views/main/xinx/zixun')
    // 公告详情
const Ggdetail = () =>
    import ('@/views/main/xinx/ggdetail')
    // 新闻中心中心动态详情
const Newsdetail = () =>
    import ('@/views/news/newsdatail')
    // 新闻中心业内动态详情
const Yenei = () =>
    import ('@/views/news/yenei')
    //新闻中心
const News = () =>
    import ('@/views/news/news')
    //新闻中心下拉菜单跳转
const Center = () =>
    import ('@/views/news/center')
    //业内中心下拉菜单跳转
const Industry = () =>
    import ('@/views/news/industry')
    //政策法规
const Policy = () =>
    import ('@/views/Thelaw/policy/policy')
    //政策法规详情页
const Falv = () =>
    import ('@/views/Thelaw/policy/falv')
    //搜索
const Search = () =>
    import ('@/views/Thelaw/policy/search')
    //全局搜索
const Qsearch = () =>
    import ('@/views/search/qsearch')
    //专题1
const Projectone = () =>
    import ('@/views/project/projectone')
    //专题2
const projecttwo = () =>
    import ('@/views/project/projecttwo')
    //专题列表
const golist = () =>
    import ('@/views/project/golist')
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/index',
    },
    {
        path: '/index',
        component: Index,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/xinx',
        name: "xinx",
        component: Xinx,
        meta: {
            keepAlive: true // 需要被缓存
        }


    },
    {
        path: '/Introduction',
        name: "Introduction",
        component: Introduction,
        // meta: {
        //     keepAlive: true // 需要被缓存
        // }


    },


    {
        path: '/setup',
        component: Setup,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/contact',
        component: Contact,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/announcement',
        component: Announcement,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/zixun',
        component: Zixun,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/yenei/:id',
        name: "yenei",
        component: Yenei,
        // meta: {
        //     keepAlive: true // 需要被缓存
        // }
    },
    {
        path: '/ggdetail/:id',
        name: "ggdetail",
        component: Ggdetail,
        // meta: {
        //     keepAlive: true // 需要被缓存
        // }
    },
    {
        path: "/newsdetail/:id",
        name: "newsdetail",
        component: Newsdetail,
        // meta: {
        //     keepAlive: true
        // }
    },
    {
        path: "/falv/:id",
        name: "falv",
        component: Falv,
        // meta: {
        //     keepAlive: true
        // }
    },
    {
        path: '/news',
        component: News,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/center',
        component: Center,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/industry',
        component: Industry,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/policy',
        component: Policy,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/search',
        component: Search,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/qsearch',
        name: "qsearch",
        component: Qsearch,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/projectone',
        name: "projectone",
        component: Projectone,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/golist',
        name: "golist",
        component: golist,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },
    {
        path: '/projecttwo',
        name: "projecttwo",
        component: projecttwo,
        meta: {
            keepAlive: true // 需要被缓存
        }
    },



]

const router = new VueRouter({
    // 使用 history 路由
    mode:   "hash",
    base: process.env.BASE_URL,
    routes
})

export default router