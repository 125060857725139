
<template>
  <div class="page">
    <Row type="flex" align="middle" class="code-row-bg">
      <Col class-name="footer_logo">
        <img src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1626684547028.png" alt="" @click="shiye()" class="shiye" />
      </Col>
      <Col class-name="footer_logog" >
        <img src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1626684504454.png" alt="" class="erwima"/>
      </Col>
      <Col class-name="footer_msg">
       <div class="footer_Host">
         <Row class-name="host">
            <p class="zhuban">浙江省药品信息宣传和发展服务中心</p>
         </Row>
         <Row class-name="juli">
            <div class="jishu"><img src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1626168617589.png" alt=""><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011853" target="view_frame" class="a">浙公网安备：33010602011853</a></div>
            <div class="jishu"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="view_frame" class="a">备案证编号 : 浙ICP备14005696-1号 </a> &nbsp; 版权所有</div>
            <div class="jishu">网站标识码 3301000048</div>
         </Row>
         
         <Row class-name="host1">
           <Col >地址：杭州市莫干山路文北巷27号</Col>
           <Col class-name="bottom-host1">|</Col>
           <Col class-name="bottom-host1">邮编：310012</Col>
         </Row>
        </div>
      </Col>
      <div class="footer_logogg">
         <img src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1626684645629.jpg" class="erwimaa" alt="">
         <div class="font">浙江省药品信息宣</div>
         <div class="font">传和发展服务中心</div>
      </div>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {
        
    };
  },
  created(){

  },
  methods:{
      shiye(){
          window.open('https://bszs.conac.cn/sitename?method=show&id=53B0CAF690FB6F2CE053022819AC98F9','_blank')
      }
  }
};
</script>
<style scoped>
.page {
  width: 1903px;
  height: 200px;
  background: RGBA(37, 51, 64, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #4d4d4d;
  margin-top: 30px;
}
.footer_logo {
  height: 200px;
  text-align: center;
  line-height: 220px;
}
.footer_msg {
  height: 200px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
}
.footer_Host {
    width: 875px;
    height: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.footer_Host2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jishu{
  white-space: nowrap;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 21px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  margin-left: 20px;
       
}

.jish{
    margin-left: -18px;
}
.erwima{
    width: 99px;
    height: 99px;
    margin-top: -40px;
    
}
.shiye{
    width: 70px;
    height: 90px;
    margin-left: 360px;
    margin-top: -60px;
}
.footer_logog{
  margin-left: 24px;
   
}
.footer_logogg{
    text-align: center;
}
.font{
    width: 140px;
    /* height: 36px; */
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /* line-height: 20px; */
    color: #FFFFFF;
    opacity: 1;
    white-space: nowrap;
}
.erwimaa{
     width: 99px;
    height: 99px;
    margin-bottom: 5px;
}
.police{
    /* width: 255px; */
    width: 280px;
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
}
.Policebadge{
    width: 27px;
    height: 29px;
    /* margin-top: 88px;
    margin-right: -18px; */
}
.zhuban{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 14px;
    font-weight: bold;
}
/* .zhuban{
    margin-top: 115px;
    margin-left: 158px;
} */
.a{
  color: white;
}
.a:hover{
  color: rgb(83, 151, 253);
}
.juli{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  justify-content: center;
}
.host{
  padding-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.host1{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  font-weight: bold;
  justify-content: center;
  margin-top: 10px;
}
.bottom-host1{
  margin-left: 15px;
}
</style>