import { request } from './request'
import { reques } from './request1'

//查询机构设置
export function queryPageOrganization(data) {
    return request({
        url: '/index/queryPageOrganization',
        method: 'post',
        data
    })
}
//查询机构简介
export function queryPageOrganizationIntro(data) {
    return request({
        url: '/index/queryPageOrganizationIntro',
        method: 'post',
        data
    })
}
//查询联系我们
export function queryPageContact(data) {
    return request({
        url: '/index/queryPageContact',
        method: 'post',
        data
    })
}
//新闻查询
export function queryPageNews(params) {
    return request({
        url: '/index/queryPageNews',
        method: 'post',
        params
    })
}
//新闻详情查询
export function queryNewsDetail(params) {
    return request({
        url: '/index/queryNewsDetail',
        method: 'post',
        params
    })
}
//查询友情链接
export function queryBlogroll(params) {
    return request({
        url: '/index/queryBlogroll',
        method: 'post',
        params
    })
}
//门户全局搜索
export function searchMessage(params) {
    return request({
        url: '/index/searchMessage',
        method: 'post',
        params
    })
}
//查询常见问题
export function queryQuestion(data) {
    return request({
        url: '/index/queryQuestion',
        method: 'post',
        data
    })
}
//查询应用
export function queryApplyUrln(data) {
    return request({
        url: '/index/queryApplyUrl',
        method: 'post',
        data
    })
}
//用户留言
export function saveLeaveMsg(params) {
    return request({
        url: '/index/saveLeaveMsg',
        method: 'post',
        params
    })
}
//轮播图
export function queryBanner(data) {
    return request({
        url: '/index/queryBanner',
        method: 'post',
        data
    })
}
//查询专题
export function querySubject(data) {
    return request({
        url: '/index/querySubject',
        method: 'post',
        data
    })
}
//查询专题下的模板和内容
export function querySubjectPlate(data) {
    return request({
        url: '/index/querySubjectPlate/' + data,
        method: 'post'
    })
}
//查询专题下的模板和内容加分页
export function querySubjectPlateContent(data) {
    return request({
        url: '/index/querySubjectPlateContent',
        method: 'post',
        data,
    })
}
//添加阅读数
export function incrReadingNum(params) {
    return request({
        url: '/index/incrReadingNum',
        method: 'post',
        params,
    })
}
//查询视频
export function getVideoUrl(data) {
    return reques({
        url: '/organization/getVideoUrl?videoId=' + data,
        method: 'get',
    })
}