import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
// import axios from 'axios'
// import '_lib-flexible@0.3.2@lib-flexible'
import 'lib-flexible/flexible'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import  "babel-polyfill"
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
// Vue.use(Antd)

// 按需引入
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//     ak: 'eHITk5vRrCE5UQYYvqPN7M2xK9E0mlh8'
// })
Vue.use(ElementUI)

Vue.prototype.$bus = new Vue();


import Print from "vue-print-nb"
Vue.use(Print);
import "./assets/css/common.css"

// Vue.prototype.$axios = axios
Vue.config.productionTip = false;
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')